export default function Header() {
  return (
    <div>
      <header className="header" data-testid="header">
        <div>
          FOOD FIGHT 2022
        </div>
      </header>
    </div>
  );
}

